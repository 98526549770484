@font-face {
	font-family: "Cooper";
	src: url(./assets/fonts/Cooper\ Black\ Regular.ttf);
	font-display: swap;
}
a:focus,
button:focus {
	box-shadow: none !important;
}
.blog a {
	text-decoration: underline;
}
.blog h3 {
	font-weight: 700;
}
